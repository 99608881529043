<template>
  <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
  >
    <div class="card w-100 border-light">
      <div class="card-header d-flex flex-row align-items-start justify-content-between">
        <div style="width: 70%" class="d-flex justify-content-start align-items-center">
          <div class="d-flex flex-column align-itesm-start justify-content-start">
            <h2 class="mb-0 font-weight-bolder">
              <span class="align-middle">Edit Question</span>
            </h2>
            <p class="font-weight-bold text-muted mb-0">
              Complete the form and click submit to update the question.
            </p>
          </div>
        </div>
        <div class="d-flex justify-content-end align-items-center">
          <b-button size="sm" @click="closeSidebar()" variant="outline-secondary"
            ><feather-icon size="24" icon="XIcon"
          /></b-button>
        </div>
      </div>
      <div class="card-body">
        <validation-observer ref="assess_form" #default="{ invalid }">
          <b-form class="mt-2" @submit.prevent="handleUpdateClick">
            <b-form-group label="Name">
              <template v-slot:label> Name <span class="text-danger">*</span> </template>
              <validation-provider
                vid="title"
                #default="{ errors }"
                name="Name"
                :rules="{
                  required: true,
                }"
              >
                <b-form-input v-model="formData.title"></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Description">
              <template v-slot:label>
                Description <span class="text-danger">*</span>
              </template>
              <validation-provider
                vid="description"
                #default="{ errors }"
                name="Description"
              >
                <b-form-textarea
                  id="description"
                  v-model="formData.description"
                  rows="4"
                  max-rows="6"
                  :state="errors.length > 0 ? false : null"
                ></b-form-textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Policies">
              <validation-provider
                vid="description"
                #default="{ errors }"
                name="Policies"
              >
                <v-select
                  v-model="formData.policies"
                  label="name"
                  placeholder="Search & Select"
                  multiple
                  :options="policyOptions"
                  @search="
                    (searchQuery) => {
                      getPoliciesAndSetOptions({ search: searchQuery });
                    }
                  "
                  :state="errors.length > 0 ? false : null"
                ></v-select>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Answer">
              <template v-slot:label>
                Answer <span class="text-danger">*</span>
              </template>
              <validation-provider
                vid="answer"
                #default="{ errors }"
                name="Answer"
                :rules="{
                  required: true,
                }"
              >
                <b-form-select
                  v-model="formData.answer"
                  :options="answerOptions"
                  @change="answerCheck(formData.answer)"
                ></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <div class="d-flex align-items-center justify-content-start">
              <b-form-group class="mr-2">
                <validation-provider
                  vid="is_evidence_rqd"
                  #default="{ errors }"
                  name="is_evidence_rqd"
                  :rules="{
                    required: false,
                  }"
                >
                  <b-form-checkbox
                    v-model="formData.evidenceRequired"
                    :value="true"
                    class="custom-control-primary"
                  >
                    Evidence Required
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group class="mr-2">
                <validation-provider
                  vid="is_response_rqd"
                  #default="{ errors }"
                  name="is_response_rqd"
                  :rules="{
                    required: false,
                  }"
                >
                  <b-form-checkbox
                    v-model="formData.responseMandatory"
                    :value="true"
                    class="custom-control-primary"
                  >
                    Response Mandatory
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group class="mr-2">
                <validation-provider
                  vid="is_justification_rqd"
                  #default="{ errors }"
                  name="is_justification_rqd"
                  :rules="{
                    required: false,
                  }"
                >
                  <b-form-checkbox
                    v-model="formData.justificationRequired"
                    :value="true"
                    class="custom-control-primary"
                  >
                    Justification
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>

            <!-- Parent Section -->
            <div class="card bg-light-secondary">
              <div
                class="card-header d-flex flex-column align-items-start justify-content-start"
              >
                <h4>Add Parent Question</h4>
                <p class="text-muted">Fill if a parent question exist</p>
              </div>
              <div class="card-body">
                <b-form-group label="Parent Question">
                  <validation-provider
                    #default="{ errors }"
                    name="User"
                    :rules="{
                      required: false,
                    }"
                  >
                    <vue-autosuggest
                      id="autosuggest__input"
                      :input-props="{
                        class: 'form-control',
                        placeholder: 'Search Questions...',
                      }"
                      :suggestions="parentQuestionSuggestions"
                      :limit="5"
                      v-model="formData.parentQuestionName"
                      @selected="onQuestionSelected"
                      @input="questionGetSuggestionValue"
                    >
                      <template slot-scope="{ suggestion }">
                        <span style="{ display: 'flex', color: 'navyblue'}">{{
                          suggestion.item.title
                        }}</span>
                      </template>
                    </vue-autosuggest>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group label="Parent Response">
                  <validation-provider
                    vid="parent_question_response"
                    #default="{ errors }"
                    name="Parent Response"
                    :rules="{
                      required: formData.parentQuestionId !== null ? true : false,
                    }"
                  >
                    <b-form-select
                      v-model="formData.parentQuestionResponse"
                      :options="answerOptions"
                    ></b-form-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </div>
            <!--Risk value-->
            <label>Risk Values <span class="text-danger">*</span></label>
            <div>
              <div>Yes</div>
              <validation-provider
                #default="{ errors }"
                name="Yes"
                :rules="{
                  required: true,
                }"
              >
                <select
                  name=""
                  id=""
                  class="form-control mb-1"
                  v-model="formData.riskValueYes"
                >
                  <option value="1">Low</option>
                  <option value="2">Medium</option>
                  <option value="3">High</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <div>No</div>
              <validation-provider
                #default="{ errors }"
                name="No"
                :rules="{
                  required: true,
                }"
              >
                <select
                  name=""
                  id=""
                  class="form-control mb-1"
                  v-model="formData.riskValueNo"
                >
                  <option value="1">Low</option>
                  <option value="2">Medium</option>
                  <option value="3">High</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <div>Not Applicable</div>
              <validation-provider
                #default="{ errors }"
                name="No"
                :rules="{
                  required: true,
                }"
              >
                <select
                  name=""
                  id=""
                  class="form-control mb-1"
                  v-model="formData.riskValueNotApplicable"
                >
                  <option value="1">Low</option>
                  <option value="2">Medium</option>
                  <option value="3">High</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>

            <b-button type="submit" variant="primary" class="mt-1" :disabled="invalid">
              <feather-icon icon="CheckIcon" class="mr-50" />
              <span class="align-middle">Update</span>
            </b-button>
          </b-form>
        </validation-observer>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BFormCheckbox,
  BFormFile,
  BFormSelect,
  BFormTextarea,
  BBadge,
  BSpinner,
  VBTooltip,
} from "bootstrap-vue";
import { VueAutosuggest } from "vue-autosuggest";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, min } from "@validations";
import ResponseMixins from "../../../../mixins/ResponseMixins";
import QuestionnaireMixins from "../../../../mixins/QuestionnaireMixins";
import UtilsMixins from "../../../../mixins/UtilsMixins";
import vSelect from "vue-select";
import PolicyMixins from "../../../../mixins/PolicyMixins";

export default {
  components: {
    BOverlay,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    BFormFile,
    BFormSelect,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    required,
    min,
    BBadge,
    BSpinner,
    VBTooltip,
    VueAutosuggest,
    vSelect,
  },
  data() {
    return {
      answerOptions: [
        { value: null, text: "--Select a value--" },
        { value: 1, text: "Yes" },
        { value: 0, text: "No" },
      ],
      policyOptions: [],
      showOverlay: false,
      parentQuestionSuggestions: [],
      formData: {
        title: null,
        description: null,
        evidenceRequired: false,
        parentQuestionId: null,
        parentQuestionName: null,
        parentQuestionResponse: null,
        responseMandatory: false,
        policies: [],
        answer: null,
        riskValueYes: null,
        riskValueYes: null,
        riskValueNotApplicable: null,
        justificationRequired: false,
      },
    };
  },
  props: {
    reloadParent: {
      type: Function,
      required: true,
    },
    closeSidebar: {
      type: Function,
      required: true,
    },
    questionId: {
      type: String,
      required: true,
    },
    questionnaire_details: {
      type: Object,
    },
  },
  mixins: [ResponseMixins, QuestionnaireMixins, UtilsMixins, PolicyMixins],
  mounted() {
    this.load();
  },
  methods: {
    answerCheck(answer) {
      if (answer == 1) {
        // if(this.questionnaire_details.questionnaire_settings)
        this.formData.evidenceRequired =
          this.questionnaire_details.questionnaire_settings.evidence_required == "true"
            ? "true"
            : "false";
        this.formData.responseMandatory =
          this.questionnaire_details.questionnaire_settings.response_mandatory == "true"
            ? "true"
            : "false";
        this.formData.justificationRequired = this.questionnaire_details.questionnaire_settings.justification.includes(
          "yes"
        );
        // console.log("am at yes" + this.questionnaire_details.questionnaire_settings.evidence_required)
      } else if (answer == 0) {
        // console.log("am at no" + this.questionnaire_details.questionnaire_settings.evidence_required)
        this.formData.evidenceRequired =
          this.questionnaire_details.questionnaire_settings.evidence_required == "false"
            ? "true"
            : "false";
        this.formData.responseMandatory =
          this.questionnaire_details.questionnaire_settings.response_mandatory == "false"
            ? "true"
            : "false";
        this.formData.justificationRequired = this.questionnaire_details.questionnaire_settings.justification.includes(
          "no"
        );
        console.log(this.formData.justificationRequired);
      } else {
      }
    },
    load() {
      this.getQuestionDetailsAndSetData(this.$route.params.id, this.questionId);
      this.getPoliciesAndSetOptions({ page: 1 });
    },

    getPoliciesAndSetOptions(params) {
      this.getPolicies(params)
        .then((res) => {
          this.policyOptions = res.data.data.data.map((p) => {
            return { name: p.title, value: p._id };
          });
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        });
    },

    getQuestionDetailsAndSetData(questionnaireId, questionId) {
      this.showOverlay = true;
      this.getQuestionDetails(questionnaireId, questionId)
        .then((res) => {
          let newFormData = res.data.data;
          this.formData.title = newFormData.title;
          this.formData.description = newFormData.description;
          this.formData.answer = newFormData.answer;
          this.formData.policies = newFormData.policies.map((p) => {
            return { name: p.title, value: p._id };
          });
          this.formData.evidenceRequired =
            newFormData.is_evidence_rqd == 1 ? true : false;
          this.formData.responseMandatory =
            newFormData.is_response_mandatory == 1 ? true : false;
          if (newFormData.parent_question && newFormData.parent_question !== null) {
            this.formData.parentQuestionId = newFormData.parent_question._id;
            this.formData.parentQuestionName = newFormData.parent_question.title;
            this.formData.parentQuestionResponse =
              newFormData.parent_question_response == ""
                ? null
                : newFormData.parent_question_response;
          }
          this.formData.riskValueYes = newFormData.risk_values.yes;
          this.formData.riskValueNo = newFormData.risk_values.no;
          this.formData.riskValueNotApplicable = newFormData.risk_values.not_applicable;
          this.formData.justificationRequired =
            newFormData.is_justification_mandatory == 1 ? true : false;
          console.log("Form Data", this.formData);
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    questionGetSuggestionValue(searchPhrase) {
      this.showOverlay = true;
      this.debounceFunction(() => {
        this.getQuestionsFromQuestionnaire(
          { limit: 5, search: searchPhrase },
          this.$route.params.id
        )
          .then((res) => {
            this.parentQuestionSuggestions = [];
            let questions = res.data.data.data;
            this.parentQuestionSuggestions.push({
              name: "questions",
              data: questions,
            });
          })
          .catch((err) => {
            this.handleError(err);
            console.log(err);
          })
          .finally(() => {
            this.showOverlay = false;
          });
      });
    },
    onQuestionSelected(question) {
      this.formData.parentQuestionId = question.item._id;
      this.formData.parentQuestionName = question.item.title;
    },
    handleUpdateClick() {
      this.showOverlay = true;
      const policyIds = this.formData.policies.map((p) => p.value);
      this.$set(this.formData, "policies", policyIds);
      this.updateQuestionOnQuestionnaire(
        this.formData,
        this.$route.params.id,
        this.questionId
      )
        .then((res) => {
          this.handleResponse(res);
          this.reloadParent();
          this.closeSidebar();
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
