<template>
  <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
  >
    <div class="d-flex flex-column align-items-start justify-content-start mt-0 w-100">
      <div class="w-100 d-flex align-items-start justify-content-between mb-2">
        <div
          class="d-flex flex-column align-items-start justify-content-start"
          style="width: 70%"
        >
          <h3 class="mb-25">{{ questionDetail.title }}</h3>
          <p class="mb-0">{{ questionDetail.description }}</p>
        </div>

        <div class="d-flex align-items-start justify-content-end" style="width: 38%">
          <b-button
            variant="primary"
            class="mr-50"
            size="sm"
            @click.stop="editQuestionClick(questionDetail._id)"
          >
            <span class="align-middle text-center">
              <feather-icon icon="EditIcon" />
              <span class="ml-25">Edit</span>
            </span>
          </b-button>
          <!-- <b-button
            variant="danger"
            size="sm"
            @click.stop="removeQuestionClick(questionDetail._id)"
          >
            <span class="align-middle text-center">
              <feather-icon icon="TrashIcon" />
              <span class="ml-25">Delete</span>
            </span>
          </b-button> -->
        </div>
      </div>

      <div
        class="d-flex align-items-center justify-content-between mb-2 w-100 border-bottom pb-75"
      >
        <div class="d-flex flex-column align-items-start justify-content-start">
          <label class="font-weight-bolder text-center">Evidence Required</label>
          <div class="w-100 d-flex align-items-center justify-content-center">
            <b-badge
              :variant="
                questionDetail.is_evidence_rqd == 1 ? 'light-success' : 'light-danger'
              "
              >{{ getMandatoryValue(questionDetail.is_evidence_rqd) }}</b-badge
            >
          </div>
        </div>

        <div class="d-flex flex-column align-items-start justify-content-start">
          <label class="font-weight-bolder text-center">Response Mandatory</label>
          <div class="w-100 d-flex align-items-center justify-content-center">
            <b-badge
              :variant="
                questionDetail.is_response_mandatory == 1
                  ? 'light-success'
                  : 'light-danger'
              "
              >{{ getMandatoryValue(questionDetail.is_response_mandatory) }}</b-badge
            >
          </div>
        </div>

        <div class="d-flex flex-column align-items-start justify-content-start">
          <label class="font-weight-bolder text-center">Answer</label>
          <div class="w-100 d-flex align-items-center justify-content-center">
            <b-badge
              :variant="questionDetail.answer == 1 ? 'light-success' : 'light-danger'"
              >{{ getMandatoryValue(questionDetail.answer) }}</b-badge
            >
          </div>
        </div>
        <div class="d-flex flex-column align-items-start justify-content-start">
          <label class="font-weight-bolder text-center">Justification</label>
          <div class="w-100 d-flex align-items-center justify-content-center">
            <b-badge
              :variant="questionDetail.is_justification_mandatory == 1 ? 'light-success' : 'light-danger'"
              >{{ getMandatoryValue(questionDetail.is_justification_mandatory) }}</b-badge
            >
          </div>
        </div>
      </div>

      <!-- <div class="d-flex flex-column align-items-start justify-content-start mb-2">
        <label class="font-weight-bolder">Description:</label>
        <p class="mb-0">{{ questionDetail.description }}</p>
      </div> -->

      <div class="d-block mb-2 w-100">
        <label class="mb-50">Risk Values</label>
        <div
          class="w-100 border rounded d-flex align-items-center justify-content-between px-50 py-1"
        >
          <div class="d-flex flex-column align-items-center justify-content-start">
            <label class="font-weight-bolder text-center mr-25">No</label>
            <div class="w-100 d-flex align-items-center justify-content-center">
              <b-badge
                :variant="mapRiskValuesToColor(Number(questionDetail.risk_values.no))"
                >{{ mapRiskValuesToText(Number(questionDetail.risk_values.no)) }}</b-badge
              >
            </div>
          </div>

          <div class="d-flex flex-column align-items-center justify-content-start">
            <label class="font-weight-bolder text-center mr-25">Yes</label>
            <div class="w-100 d-flex align-items-center justify-content-center">
              <b-badge
                :variant="mapRiskValuesToColor(Number(questionDetail.risk_values.yes))"
                >{{
                  mapRiskValuesToText(Number(questionDetail.risk_values.yes))
                }}</b-badge
              >
            </div>
          </div>

          <div class="d-flex flex-column align-items-center justify-content-start">
            <label class="font-weight-bolder text-center mr-25">Not Applicable</label>
            <div class="w-100 d-flex align-items-center justify-content-center">
              <b-badge
                :variant="
                  mapRiskValuesToColor(Number(questionDetail.risk_values.not_applicable))
                "
                >{{
                  mapRiskValuesToText(Number(questionDetail.risk_values.not_applicable))
                }}</b-badge
              >
            </div>
          </div>
        </div>
      </div>
      <div
        class="d-flex flex-column align-items-start justify-content-start mb-2"
        v-if="
          questionDetail.policies &&
          questionDetail.policies != null &&
          questionDetail.policies.length > 0
        "
      >
        <label class="font-weight-bolder">Policies:</label>
        <div class="w-100 d-flex flex-column align-items-start justify-content-start">
          <ul>
            <template v-for="policy in questionDetail.policies">
              <li :key="policy._id" class="mb-50">
                {{ policy.title }}
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { BOverlay, BBadge, BButton, BAvatar } from "bootstrap-vue";
import ResponseMixins from "@/mixins/ResponseMixins";
import QuestionnaireMixins from "@/mixins/QuestionnaireMixins";
import moment from "moment";

export default {
  mixins: [ResponseMixins, QuestionnaireMixins],
  components: {
    BOverlay,
    BBadge,
    BButton,
    moment,
    BAvatar,
  },

  data() {
    return {
      showOverlay: false,
      questionDetail: null,
    };
  },

  props: {
    questionnaireId: {
      type: String,
      required: true,
    },
    questionId: {
      type: String,
      required: true,
    },
    editQuestionClick: {
      type: Function,
      required: true,
    },
    removeQuestionClick: {
      type: Function,
      required: true,
    },
  },

  filters: {
    moment: function (date) {
      return moment(date).format("D-MMM-YY");
    },
  },

  mounted() {
    this.load();
  },

  methods: {
    load() {
      this.getQuestionDetailsAndSetData(this.questionnaireId, this.questionId);
    },

    getQuestionDetailsAndSetData(questionnaireId, questionId) {
      this.showOverlay = true;
      this.getQuestionDetails(questionnaireId, questionId)
        .then((res) => {
          console.log(res);
          this.questionDetail = res.data.data;
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    getMandatoryValue(num) {
      if (num !== null) {
        let newNum = Number(num);
        switch (newNum) {
          case 1:
            return "Yes";
          case 0:
            return "No";
          default:
            return "No";
        }
      }
    },

    mapRiskValuesToColor(num) {
      switch (num) {
        case 1:
          return "success";
        case 2:
          return "warning";
        case 3:
          return "danger";
        default:
          return "secondary";
      }
    },

    mapRiskValuesToText(num) {
      switch (num) {
        case 1:
          return "Low";
        case 2:
          return "Medium";
        case 3:
          return "High";
        default:
          return "Unknown";
      }
    },
  },
};
</script>
