var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"opacity":"0.17","blur":"1rem","show":_vm.showOverlay,"rounded":"md","variant":"secondary"}},[_c('div',{staticClass:"card w-100 border-light"},[_c('div',{staticClass:"card-header d-flex flex-row align-items-start justify-content-between"},[_c('div',{staticClass:"d-flex justify-content-start align-items-center",staticStyle:{"width":"70%"}},[_c('div',{staticClass:"d-flex flex-column align-itesm-start justify-content-start"},[_c('h2',{staticClass:"mb-0 font-weight-bolder"},[_c('span',{staticClass:"align-middle"},[_vm._v("Create new question")])]),_c('p',{staticClass:"font-weight-bold text-muted mb-0"},[_vm._v(" Complete the form and click submit to create new question. ")])])]),_c('div',{staticClass:"d-flex justify-content-end align-items-center"},[_c('b-button',{attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":function($event){return _vm.closeSidebar()}}},[_c('feather-icon',{attrs:{"size":"24","icon":"XIcon"}})],1)],1)]),_c('div',{staticClass:"card-body"},[_c('validation-observer',{ref:"assess_form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmitClick.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"vid":"title","name":"Name","rules":{
                required: true,
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Description"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Description "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"vid":"description","name":"Description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"description","rows":"4","max-rows":"6","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Policies"}},[_c('validation-provider',{attrs:{"vid":"description","name":"Policies"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","placeholder":"Search & Select","multiple":"","options":_vm.policyOptions,"state":errors.length > 0 ? false : null},on:{"search":function (searchQuery) {
                    _vm.getPoliciesAndSetOptions({ search: searchQuery });
                  }},model:{value:(_vm.formData.policies),callback:function ($$v) {_vm.$set(_vm.formData, "policies", $$v)},expression:"formData.policies"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Answer"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Answer "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"vid":"answer","name":"Answer","rules":{
                required: true,
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.answerOptions},on:{"change":function($event){return _vm.answerCheck(_vm.formData.answer)}},model:{value:(_vm.formData.answer),callback:function ($$v) {_vm.$set(_vm.formData, "answer", $$v)},expression:"formData.answer"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"d-flex align-items-center justify-content-start"},[_c('b-form-group',{staticClass:"mr-2"},[_c('validation-provider',{attrs:{"vid":"is_evidence_rqd","name":"is_evidence_rqd","rules":{
                  required: false,
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"custom-control-primary",attrs:{"value":true},model:{value:(_vm.formData.evidenceRequired),callback:function ($$v) {_vm.$set(_vm.formData, "evidenceRequired", $$v)},expression:"formData.evidenceRequired"}},[_vm._v(" Evidence Required ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{staticClass:"mr-2"},[_c('validation-provider',{attrs:{"vid":"is_response_rqd","name":"is_response_rqd","rules":{
                  required: false,
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"custom-control-primary",attrs:{"value":true},model:{value:(_vm.formData.responseMandatory),callback:function ($$v) {_vm.$set(_vm.formData, "responseMandatory", $$v)},expression:"formData.responseMandatory"}},[_vm._v(" Response Mandatory ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{staticClass:"mr-2"},[_c('validation-provider',{attrs:{"vid":"is_justification_rqd","name":"is_justification_rqd","rules":{
                  required: false,
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"custom-control-primary",attrs:{"value":true},model:{value:(_vm.formData.justificationRequired),callback:function ($$v) {_vm.$set(_vm.formData, "justificationRequired", $$v)},expression:"formData.justificationRequired"}},[_vm._v(" Justification ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"card bg-light-secondary"},[_c('div',{staticClass:"card-header d-flex flex-column align-items-start justify-content-start"},[_c('h4',[_vm._v("Add Parent Question")]),_c('p',{staticClass:"text-muted"},[_vm._v("Fill if a parent question exist")])]),_c('div',{staticClass:"card-body"},[_c('b-form-group',{attrs:{"label":"Parent Question"}},[_c('validation-provider',{attrs:{"name":"User","rules":{
                    required: false,
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('vue-autosuggest',{attrs:{"id":"autosuggest__input","input-props":{
                      class: 'form-control',
                      placeholder: 'Search Questions...',
                    },"suggestions":_vm.parentQuestionSuggestions,"limit":5},on:{"selected":_vm.onQuestionSelected,"input":_vm.questionGetSuggestionValue},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var suggestion = ref.suggestion;
return [_c('span',{staticStyle:{"{ display":"'flex', color: 'navyblue'}"}},[_vm._v(_vm._s(suggestion.item.title))])]}}],null,true),model:{value:(_vm.formData.parentQuestionName),callback:function ($$v) {_vm.$set(_vm.formData, "parentQuestionName", $$v)},expression:"formData.parentQuestionName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Parent Response"}},[_c('validation-provider',{attrs:{"vid":"parent_question_response","name":"Parent Response","rules":{
                    required: _vm.formData.parentQuestionId !== null ? true : false,
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.answerOptions},model:{value:(_vm.formData.parentQuestionResponse),callback:function ($$v) {_vm.$set(_vm.formData, "parentQuestionResponse", $$v)},expression:"formData.parentQuestionResponse"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)]),_c('label',[_vm._v("Risk Values"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',[_c('div',[_vm._v("Yes")]),_c('validation-provider',{attrs:{"name":"Yes","rules":{
                required: true,
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.riskValueYes),expression:"formData.riskValueYes"}],staticClass:"form-control mb-1",attrs:{"name":"","id":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.formData, "riskValueYes", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"1"}},[_vm._v("Low")]),_c('option',{attrs:{"value":"2"}},[_vm._v("Medium")]),_c('option',{attrs:{"value":"3"}},[_vm._v("High")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('div',[_vm._v("No")]),_c('validation-provider',{attrs:{"name":"No","rules":{
                required: true,
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.riskValueNo),expression:"formData.riskValueNo"}],staticClass:"form-control mb-1",attrs:{"name":"","id":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.formData, "riskValueNo", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"1"}},[_vm._v("Low")]),_c('option',{attrs:{"value":"2"}},[_vm._v("Medium")]),_c('option',{attrs:{"value":"3"}},[_vm._v("High")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('div',[_vm._v("Not Applicable")]),_c('validation-provider',{attrs:{"name":"Not Applicable","rules":{
                required: true,
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.riskValueNotApplicable),expression:"formData.riskValueNotApplicable"}],staticClass:"form-control mb-1",attrs:{"name":"","id":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.formData, "riskValueNotApplicable", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"1"}},[_vm._v("Low")]),_c('option',{attrs:{"value":"2"}},[_vm._v("Medium")]),_c('option',{attrs:{"value":"3"}},[_vm._v("High")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{staticClass:"mt-1",attrs:{"type":"submit","variant":"primary","disabled":invalid}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CheckIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Submit")])],1)],1)]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }