<template>
  <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
  >
    <b-sidebar
      id="question-detail-sidebar"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
      lazy
     
    >
      <sidebar-template
        title="Question Details"
        :closeAction="hideQuestionDetailsSidebar"
      >
        <template #content>
          <question-details-view
            :questionId="selectedQuestionId"
            :questionnaireId="questionnaire._id"
            :editQuestionClick="editQuestionClick"
            :removeQuestionClick="removeQuestionClick"
          />
        </template>
      </sidebar-template>
    </b-sidebar>
    <b-sidebar
      lazy
      id="sidebar-add-questionnire"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
    >
      <add-question-form
        :closeSidebar="closeAddQuestionSidebar"
        :reloadParent="load"
        :questionnaire_details="questionnaire"
      />
    </b-sidebar>
    <b-sidebar
      lazy
      id="sidebar-edit-questionnire"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
    >
      <edit-question-form
        v-if="selectedQuestionId && selectedQuestionId !== null"
        :closeSidebar="closeEditQuestionSidebar"
        :reloadParent="load"
        :questionId="selectedQuestionId"
        :questionnaire_details="questionnaire"
      />
    </b-sidebar>
    <div class="card">
      <div class="card-header pb-75" style="padding-right: 0">
        <div class="w-100 row justify-content-between align-items-center">
          <div
            class="d-flex flex-column align-items-start justify-content-start col-md-12 col-sm-12"
          >
            <h3 class="mb-25 font-weight-bolder" v-if="questionnaire !== null">
              {{ questionnaire.title }}
            </h3>
            <p class="mb-50" v-if="questionnaire !== null">
              {{ questionnaire.description }}
            </p>
          </div>
          <div class="col-md-12 col-sm-12">
            <div
              class="w-100 d-flex align-items-center justify-content-md-end justify-content-sm-end"
            >
              <b-button
                v-if="questionnaire !== null"
                size="sm"
                variant="success"
                @click="handleAddQuestionClick"
              >
                <feather-icon icon="PlusIcon" class="mr-50" /><span class="align-middle"
                  >New Question</span
                >
              </b-button>
            </div>
          </div>
        </div>
      </div>

      <div class="pb-25 pt-0 w-100 row">
        <!-- <div class="col-sm-12">
          <div>
            <p class="font-weight-bold">Questions</p>
          </div>
        </div> -->
        <div class="app-fixed-search col-md-6 col-sm-12">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                v-if="filters.search == null || filters.search == ''"
                icon="SearchIcon"
                class="text-muted"
              />
              <feather-icon
                @click="
                  () => {
                    filters.search = null;
                  }
                "
                v-else
                icon="XIcon"
                class="text-danger cursor-pointer"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="filters.search"
              placeholder="Search name..."
              debounce="500"
              size="md"
            />
          </b-input-group>
        </div>
        <div class="col-md-6 col-sm-12">
          <v-select
            v-model="filters.policies"
            label="name"
            placeholder="Search Policies.."
            size="sm"
            multiple
            :options="policyOptions"
            @search="
              (searchQuery) => {
                getPoliciesAndSetOptions({ search: searchQuery });
              }
            "
          ></v-select>
        </div>
      </div>
      <!-- 
      <div
        class="card-header d-flex flex-row align-items-start justify-content-between"
        v-if="questionnaire !== null"
      >
        <div
          class="d-flex flex-column align-items-start justify-content-start w-75"
        >
          <h2 class="text-primary">{{ questionnaire.title }}</h2>
          <p>{{ questionnaire.description }}</p>
        </div>
      </div> -->

      <table role="table" class="table table-condensed table-hover">
        <thead role="rowgroup">
          <tr role="row">
            <th role="columnheader" scope="col">#</th>
            <th role="columnheader" scope="col">Question</th>
            <!-- <th role="columnheader" scope="col">Description</th> -->
            <th role="columnheader" scope="col" class="text-center">Evidence Required</th>
            <th role="columnheader" scope="col" class="text-center">
              Response Mandatory
            </th>
            <th role="columnheader" scope="col" class="text-center">Answer</th>
            <th role="columnheader" scope="col" class="text-center">Actions</th>
          </tr>
        </thead>
        <tbody role="rowgroup">
          <template
            v-if="
              filteredQuestions &&
              filteredQuestions != null &&
              filteredQuestions.length > 0
            "
          >
            <tr
              v-for="(question, index) in filteredQuestions"
              :key="question._id"
              role="row"
              @click="
                () => {
                  handleQuestionClicked(question._id);
                }
              "
            >
              <td role="cell">
                {{ index + 1 }}
              </td>
              <td role="cell" style="max-width: 25vw">
                <p class="font-weight-bold mb-25">
                  {{ question.title }}
                </p>
                <small>{{ question.description }}</small>
              </td>

              <!-- <td role="cell" style="max-width: 25vw">
                <p class="text-left font-italic mb-0">
                  {{ question.description }}
                </p>
              </td> -->

              <td role="cell">
                <div class="d-flex justify-content-center">
                  <!-- <b-avatar
                  :text="getMandatoryValue(question.is_evidence_rqd)"
                  :variant="
                    question.is_evidence_rqd == 1 ? 'success' : 'danger'
                  "
                /> -->
                  <b-badge
                    :variant="
                      question.is_evidence_rqd == 1 ? 'light-success' : 'light-danger'
                    "
                    >{{ getMandatoryValue(question.is_evidence_rqd) }}</b-badge
                  >
                </div>
              </td>

              <td role="cell">
                <div class="d-flex justify-content-center">
                  <!-- <b-avatar
                  :text="getMandatoryValue(question.is_response_mandatory)"
                  :variant="question.is_response_mandatory == 1 ? 'success' : 'danger'"
                /> -->
                  <b-badge
                    :variant="
                      question.is_response_mandatory == 1
                        ? 'light-success'
                        : 'light-danger'
                    "
                    >{{ getMandatoryValue(question.is_response_mandatory) }}</b-badge
                  >
                </div>
              </td>

              <td role="cell">
                <div class="d-flex justify-content-center">
                  <!-- <b-avatar
                  :text="getMandatoryValue(question.answer)"
                  :variant="question.answer == 1 ? 'success' : 'danger'"
                /> -->
                  <b-badge
                    :variant="question.answer == 1 ? 'light-success' : 'light-danger'"
                    >{{ getMandatoryValue(question.answer) }}</b-badge
                  >
                </div>
              </td>

              <td aria-colindex="2" role="cell">
                <div class="d-flex align-items-center justify-content-center">
                  <b-button
                    variant="primary"
                    class="mr-50"
                    size="sm"
                    @click.stop="editQuestionClick(question._id)"
                  >
                    <span class="align-middle text-center">
                      <feather-icon icon="EditIcon" />
                      <span class="ml-25">Edit</span>
                    </span>
                  </b-button>
                  <b-button
                    variant="danger"
                    size="sm"
                    @click.stop="removeQuestionClick(question._id)"
                  >
                    <span class="align-middle text-center">
                      <feather-icon icon="TrashIcon" />
                      <span class="ml-25">Delete</span>
                    </span>
                  </b-button>
                </div>
              </td>
            </tr>
          </template>
          <template v-else>
            <empty-table-section title="Questions Empty">
              <template #content>
                <p class="text-center">It seems there are no Questions at the moment.</p>
              </template>
            </empty-table-section>
          </template>
        </tbody>
      </table>

      <!-- <div class="card-body" v-if="questions !== null">
        <div class="d-flex align-items-start justify-content-start my-1">
          <b-button variant="success" @click="handleAddQuestionClick"
            ><feather-icon icon="PlusIcon" class="mr-50" />New
            Question</b-button
          >
        </div>
        <table role="table" class="table table-condensed table-hover">
          <thead role="rowgroup">
            <tr role="row">
              <th role="columnheader" scope="col">#</th>
              <th role="columnheader" scope="col">Name</th>
              <th role="columnheader" scope="col">Description</th>
              <th role="columnheader" scope="col" class="text-center">
                Evidence Required
              </th>
              <th role="columnheader" scope="col" class="text-center">
                Response Mandatory
              </th>
              <th role="columnheader" scope="col" class="text-center">
                Justification
              </th>
              <th role="columnheader" scope="col" class="text-center">
                Answer
              </th>
              <th role="columnheader" scope="col" class="text-center">
                Actions
              </th>
            </tr>
          </thead>
          <tbody role="rowgroup">
            <tr
              v-for="(question, index) in questions"
              :key="question._id"
              role="row"
              @click="
                () => {
                  handleQuestionClicked(question._id);
                }
              "
            >
              <td role="cell">
                {{ index + 1 }}
              </td>
              <td role="cell" style="max-width: 25vw">
                <p class="text-left mb-0">
                  {{ question.title }}
                </p>
              </td>

              <td role="cell" style="max-width: 25vw">
                <p class="text-left font-italic mb-0">
                  {{ question.description }}
                </p>
              </td>

              <td role="cell">
                <div class="d-flex justify-content-center">
                  <b-avatar
                    :text="getMandatoryValue(question.is_evidence_rqd)"
                    :variant="
                      question.is_evidence_rqd == 1
                        ? 'light-success'
                        : 'light-danger'
                    "
                  />
                </div>
              </td>

              <td role="cell">
                <div class="d-flex justify-content-center">
                  <b-avatar
                    :text="getMandatoryValue(question.is_response_mandatory)"
                    :variant="
                      question.is_response_mandatory == 1
                        ? 'light-success'
                        : 'light-danger'
                    "
                  />
                </div>
              </td>
              <td role="cell">
                <div class="d-flex justify-content-center">
                  <b-avatar
                    :text="getMandatoryValue(question.is_justification_mandatory)"
                    :variant="
                      question.is_justification_mandatory == 1 ? 'light-success' : 'light-danger'
                    "
                  />
                </div>
              </td>
              <td role="cell">
                <div class="d-flex justify-content-center">
                  <b-avatar
                    :text="getMandatoryValue(question.answer)"
                    :variant="
                      question.answer == 1 ? 'light-success' : 'light-danger'
                    "
                  />
                </div>
              </td>

              <td aria-colindex="2" role="cell">
                <div class="d-flex align-items-center justify-content-center">
                  <b-button
                    variant="primary"
                    class="btn-icon mr-50"
                    b-tooltip.hover
                    title="Edit Question"
                    @click.stop="editQuestionClick(question._id)"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                  <b-button
                    variant="danger"
                    class="btn-icon"
                    b-tooltip.hover
                    title="Remove Question"
                    @click.stop="removeQuestionClick(question._id)"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div> -->
    </div>
  </b-overlay>
</template>

<script>
import ResponseMixins from "../../../mixins/ResponseMixins";
import QuestionnaireMixins from "../../../mixins/QuestionnaireMixins";
import {
  BBadge,
  BOverlay,
  BAvatar,
  BButton,
  VBTooltip,
  BSidebar,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
} from "bootstrap-vue";
import TaskMixins from "../../../mixins/TaskMixins";
import AddQuestionForm from "./components/AddQuestionForm.vue";
import EditQuestionForm from "./components/EditQuestionForm.vue";
import SidebarTemplate from "../../../components/SidebarTemplate.vue";
import QuestionDetailsView from "./components/QuestionDetailsView.vue";
import vSelect from "vue-select";
import PolicyMixins from "../../../mixins/PolicyMixins";
import EmptyTableSection from "../../../components/EmptyTableSection.vue";

export default {
  components: {
    BBadge,
    BOverlay,
    BAvatar,
    BButton,
    AddQuestionForm,
    BSidebar,
    EditQuestionForm,
    SidebarTemplate,
    QuestionDetailsView,
    vSelect,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    EmptyTableSection,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },

  computed: {
    filteredQuestions() {
      if (!this.questions) {
        return [];
      }

      let filtered = this.questions;

      // Apply search filter
      if (this.filters.search) {
        const searchTerm = this.filters.search.toLowerCase();
        filtered = filtered.filter((question) => {
          return (
            question.title.toLowerCase().includes(searchTerm) ||
            question.description.toLowerCase().includes(searchTerm)
          );
        });
      }

      // Apply policies filter
      if (this.filters.policies.length > 0) {
        filtered = filtered.filter((question) => {
          if (question.policy_ids && question.policy_ids != null) {
            return this.filters.policies.some((policy) => {
              return question.policy_ids.includes(policy.value);
            });
          }
        });
      }

      return filtered;
    },
  },

  data() {
    return {
      selectedQuestionId: null,
      showOverlay: false,
      questionnaire: null,
      questions: null,
      filters: {
        search: null,
        policies: [],
      },
      policyOptions: [],
    };
  },
  mixins: [ResponseMixins, QuestionnaireMixins, TaskMixins, PolicyMixins],
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.getQuestionnaireDetailsAndSetData(this.$route.params.id);
      this.getPoliciesAndSetOptions({ page: 1 });
    },

    getPoliciesAndSetOptions(params) {
      this.getPolicies(params)
        .then((res) => {
          this.policyOptions = res.data.data.data.map((p) => {
            return { name: p.title, value: p._id };
          });
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        });
    },

    handleQuestionClicked(id) {
      this.selectedQuestionId = id;
      this.showQuestionDetailsSidebar();
    },

    showQuestionDetailsSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "question-detail-sidebar");
    },

    hideQuestionDetailsSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "question-detail-sidebar");
    },

    editQuestionClick(questionId) {
      this.selectedQuestionId = questionId;
      this.openEditQuestionSidebar();
    },
    closeEditQuestionSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-edit-questionnire");
    },
    openEditQuestionSidebar: function () {
      this.$nextTick().then(() => {
        this.$root.$emit("bv::toggle::collapse", "sidebar-edit-questionnire");
      });
    },
    removeQuestionClick(questionId) {
      this.$bvModal
        .msgBoxConfirm(
          "Please confirm that you want to delete this Question permanently",
          {
            title: "Warning",
            size: "sm",
            headerBgVariant: "light-warning",
            okVariant: "danger",
            okTitle: "Delete",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.showOverlay = true;
            this.removeQuestionFromQuestionnaire(this.$route.params.id, questionId)
              .then((res) => {
                this.handleResponse(res);
                this.load();
              })
              .catch((err) => {
                this.handleError(err);
              })
              .finally(() => {
                this.showOverlay = false;
              });
          }
        });
    },
    handleAddQuestionClick() {
      this.openAddQuestionSidebar();
    },
    closeAddQuestionSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-add-questionnire");
    },
    openAddQuestionSidebar: function () {
      this.$nextTick().then(() => {
        this.$root.$emit("bv::toggle::collapse", "sidebar-add-questionnire");
      });
    },
    getQuestionnaireDetailsAndSetData(questionnaireId) {
      this.showOverlay = true;
      this.getImportedQuestionnaireDetails(questionnaireId)
        .then((res) => {
          this.questionnaire = res.data.data;
          this.questions = res.data.data.questions;
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
    getResponseValue(num) {
      if (num !== null) {
        let newNum = Number(num);
        switch (newNum) {
          case 1:
            return "Yes";
          case 2:
            return "No";
          case 3:
            return "Not Applicable";
          default:
            return null;
        }
      }
    },
    getMandatoryValue(num) {
      if (num !== null) {
        let newNum = Number(num);
        switch (newNum) {
          case 1:
            return "Yes";
          case 0:
            return "No";
          default:
            return "No";
        }
      }
    },
  },
};
</script>

<style lang="css">
.b-sidebar.sidebar-lg {
  width: 70rem;
}
</style>
<style lang="scss" scoped>
.table-condensed > thead > tr > th,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > td {
  padding: 0.8rem;
}
</style>

<style lang="scss">
.app-fixed-search {
  background-color: transparent;

  .input-group:focus-within {
    box-shadow: none;
  }

  input,
  .input-group-text {
    border: 0;
    background-color: transparent;
  }
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
